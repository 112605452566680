<template>
  <div class="yulan">
    <div v-if="showDoc" class="word">
      <iframe :src="file" width="100%" height="100%" frameborder="0" />
    </div>
    <div v-else-if="showPdf" class="pdf">
      <embed :src="file" style="width:100%;height:100%" />
    </div>
    <div v-else-if="showImg" class="imag">
      <img :src="file" alt="" />
    </div>
  </div>
</template>
<script>
import { Base64 } from 'js-base64'
import screenfull from 'screenfull'
const request_base_path = require('@/utils/base_url')
export default {
  props: ["url", "type"],
  data() {
    return {
      wordUrl: "https://view.officeapps.live.com/op/view.aspx?src=",
      filePreview: request_base_path.dev_url + 'file-preview/onlinePreview?url=', // 数据预览
      picturesPreview: request_base_path.pro_url + 'file-preview/picturesPreview?urls=', // 多图预览
      fileTask: request_base_path.pro_url + 'file-preview/addTask?url=', // 数据预埋
      // wordUrl: "http://vw.usdoc.cn/?src=",
      //  wordUrl: 'http://view.xdocin.com/xdoc?_xdoc=',
      file: "",
      showDoc: false,
      showPdf: false,
      showImg: false,
      wordExcl: ["doc", "docx", 'dotx', "xls", "xlsx", 'xlsb', 'xlsm', 'pptx', 'ppsx', 'ppt', 'pps', 'potx', 'ppsm'],
      imgs: ["jpg", "png", "jpeg", "gif"]
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // const type = this.type;
      // debugger
      this.file = this.filePreview + encodeURIComponent(Base64.encode(this.url));
      // if (this.wordExcl.includes(type)) {
      //   this.showDoc = true;
      // } else if (type.indexOf("pdf") != -1) {
      //   this.showPdf = true;
      // } else if (this.imgs.includes(type)) {
      //   this.showImg = true;
      // } else {
      //   this.$message.error("此文件无法预览");
      // }
    },
  }
};
</script>
<style lang="scss" scoped>
.yulan {
  height: 600px;
  overflow-x: hidden;

  .word,
  .pdf {
    width: 100%;
    height: 100%;
  }
}
</style>
