<template>
  <div style="display: inline-block">
    <!-- 云上传 -->
    <template v-if="whether == 'true'">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="#"
        :multiple="multiple"
        :show-file-list="showFile"
        :on-remove="handleRemove"
        :http-request="httpRequest"
        :file-list="fileList"
      >
        <el-button :type="btnType">
          {{ btnMsg }}
          <i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </el-upload>
    </template>
    <!-- 本地上传 -->
    <template v-if="whether == 'false'">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="#"
        :headers="headers"
        :multiple="multiple"
        :auto-upload="false"
        :data="fileData"
        :show-file-list="showFile"
        :on-remove="handleRemove"
        :on-progress="handleProgress"
        :on-change="changeFile"
        :before-upload="beforeUpload"
        :before-remove="beforeRemove"
        :file-list="fileList"
      >
        <el-button :type="btnType" size="mini">
          {{ btnMsg }}
          <i class="el-icon-upload2 el-icon--right"></i>
        </el-button>
        <div slot="file" slot-scope="{file}">
          <div class="file-item">
            <div class="file-info">
              <div class="el-icon-document icon"></div>
              <div class="file-name">{{ file.name }}</div>
            </div>
            <div class="tool-icon">
              <div class="el-icon-zoom-in icon" title="查看" @click.stop="handleShow(file)"></div>
              <div class="el-icon-download icon" title="下载" @click.stop="handleDown(file)"></div>
              <div class="el-icon-delete icon" title="删除" @click.stop="handleDel(file)"></div>
            </div>
          </div>
        </div>
      </el-upload>
      <el-dialog
        class="show_dialog"
        title="文件预览"
        :visible.sync="dialogVisible"
        width="50%"
        append-to-body
      >
        <FileView v-if="flag" :url="url" :type="file_type" />
      </el-dialog>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
// 云上传
import { uploadFile, getExt } from "@/utils/upload"
import md5 from "js-md5"
// 本地
import { getToken } from "@/utils/auth"
import FileView from "./file-view.vue"
const schoolid = sessionStorage.getItem("schoolid") * 1
export default {
  components: {
    FileView
  },
  props: {
    // 父组件展示的文件
    files: {
      type: Array,
      default: () => new Array(),
    },
    // 多选-单选
    multiple: {
      type: Boolean,
      default: true,
    },
    // 是否显示
    showFile: {
      type: Boolean,
      default: true,
    },
    // 循环组件中上传使用
    loops: {
      type: Object,
    },
    // 云文件区分
    cloudType: {
      type: String,
    },
    // 业务模块名称
    module: {
      type: String,
      default: "",
    },
    // 素材包是否解压(0 不解压 1解压)
    is_unzip: {
      type: String | Number,
      default: 0,
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    // 按钮配置
    btnType: {
      type: String,
      default: 'primary',
    },
    btnMsg: {
      type: String,
      default: '选择文件',
    },
    // 问题Id
    qid: {
      type: [Number, String],
      default: -1
    },
    // 答案Id
    aid: {
      type: [Number, String],
      default: -1
    },
  },
  data () {
    return {
      fileList: [], // 已上传的文件列表
      uploadSrc: process.env.VUE_APP_BASE_API + "/common/upload-file/upload",
      headers: {
        Authorization: "Bearer " + getToken(),
        fromtype: 2,
        identifier: 2,
        schoolid: schoolid ? schoolid : 1,
        "app-version": "",
        "request-time": "",
        "request-sign": "",
      },
      fileData: {
        is_unzip: this.is_unzip,
        module: this.module,
      },
      whether: "",
      fileArr: [],
      oldFiles: "",
      newFiles: [],
      dialogVisible: false,
      flag: false,
      url: "",
      file_type: "",
      fileLen: 0,
      uploadFileList: [], // 存放changeFile的文件
      pageloading: null,
      resFileList: [], // 存放上传成功的文件
      timer: null
    }
  },
  watch: {
    // 监视页面展示的列表
    files: {
      handler (val) {
        // debugger
        // 获取已上传的文件列表
        if (val && val.length > 0) {
          this.fileList = val
        } else {
          this.fileList = []
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created () {
    // 获取 whether，确认是否是云上传
    this.whether = sessionStorage.getItem("whether")
    this.setHeaders() // 配置上传默认请求头

    // 深拷贝
    this.oldFiles = JSON.stringify(this.files)
    this.newFiles = JSON.parse(this.oldFiles)
  },
  methods: {
    async submitFile () {
      let formData = new FormData()
      // 将上传的文件放到数据对象中
      // debugger
      // console.log('this.uploadFileList', this.uploadFileList);
      this.uploadFileList.forEach((file) => {
        // debugger
        if (!(file?.status && file.status === "success")) {
          formData.append('file[]', file.raw)
        }
        // formData.append('file[]', file.raw)
        // console.log('formData', formData)
      })
      let resFile = await axios({ url: this.uploadSrc, method: 'post', headers: this.headers, data: formData }) // 上传文件
      // debugger
      this.pageloading.close()
      if (resFile.data && resFile.data.code === 0) {
        // debugger
        // console.log('resFile', resFile);
        this.resFileList = resFile.data.data
        // this.resFileList = this.uploadFileList
        // debugger
        // console.log('this.resFileList', this.resFileList)
        let key = { qid: this.qid, aid: this.aid } // 富文本对应的ID，方便对应回复的问题
        this.$emit("setUrl", JSON.stringify(this.resFileList), key)
        // this.$emit("setUrl", this.resFileList, key)
      }
    },
    // 配置上传默认请求头
    setHeaders () {
      const date = new Date()
      const c_time = Math.trunc(date.getTime() / 1000)
      const sign_time = c_time.toString()
      const app_version = "v1.0"
      this.headers["app-version"] = app_version
      this.headers["request-time"] = c_time
      this.headers["request-sign"] = md5(md5(sign_time) + app_version)
    },
    /**************************** 上传前准备 ****************************/
    // 文件状态改变时(添加文件、上传成功和上传失败)
    changeFile (file, fileList) {
      // debugger
      // let sss = this.$refs.upload.uploadFiles
      if (file.status !== 'ready') return // 阻止上传成功的触发
      if (this.timer) return
      this.timer = setTimeout(() => {
        try {
          this.pageloading = this.$loading({
            lock: true,
            text: "文件正在上传，请勿关闭浏览器或刷新页面……",
            spinner: "",
            background: "rgba(255, 255, 255, 0.4)",
          })
          // debugger
          this.uploadFileList = fileList
          this.submitFile()
        } catch (error) {

        } finally {
          this.timer = null
        }
      }, 1000)
      // console.log("选择文件", file, fileList);
      // this.setHeaders() // 实时配置上传默认请求头
      // // 判断是否在循环组件中上传使用
      // if (this.loops) {
      //   this.loops.show = true // 显示循环组件
      // }
      // // 判断是否自动上传
      // if (file.response) {
      //   // 判断自动上传是否成功
      //   if (file.response.code === 0) {
      //     // 判断是否在循环组件中上传使用
      //     if (this.loops) {
      //       this.loops.show = false // 关闭循环组件
      //     }
      //   } else {
      //     this.$message.error(file.response.message) // 上传失败，提示原因
      //   }
      // }

      // this.loop(fileList, 1)
    },
    // 循环处理，将参数传到父组件
    loop (fileList, num) {
      let arr = [] // 自定义数组接收已上传文件的信息
      fileList.forEach((item) => {
        // console.log(item.uid,item.url);
        // 判断是否是上传的文件
        if (item.uid) {
          // 通过文件路径判断是否是已上传文件
          if (item.url) {
            const params = {
              url: item.url,
              name: item.name,
              size: item.size,
              ext: item.ext,
            }
            arr.push(params)
          }
          // 判断是否是当前上传的文件
          if (item.response) {
            // 判断自动上传是否成功 且有返回值
            if (item.response.code === 0 && item.response.data && item.response.data.length > 0) {
              const params = {
                url: item.response.data[0].url,
                name: item.name,
                size: item.size,
                ext: item.response.data[0].ext,
              }
              arr.push(params)
              if (this.isClear) {
                this.$refs.upload.clearFiles()
              }
              // this.$refs.upload.clearFiles()
            }
          }
        }
      })

      let key = { qid: this.qid, aid: this.aid, num: num } // 富文本对应的ID，方便对应回复的问题
      // 判断是否在循环组件中上传使用
      // debugger
      if (this.loops) {
        let params = { src: arr, loop: this.loops }
        this.$emit("setUrl", params, key)
      } else {
        this.$emit("setUrl", JSON.stringify(arr), key)
      }
    },
    // 上传文件之前，返回false或 Promise且被reject 则 禁止上传
    beforeUpload (file) {
      // console.log("上传文件之前：",file);
    },
    /**************************** 普通上传：文件走组件自带的上传方法 ****************************/
    // 文件上传中……
    handleProgress (event, file, fileList) {
      // console.log("文件上传中：",event, file, fileList);
    },

    /**************************** 云上传：文件走自定义上传方法，上传到云端 ****************************/
    // 云上传方法覆盖
    httpRequest (option) {
      // //console.log(option, 'option选中得文件')
      this.fileArr = []
      uploadFile(option.file, this.afterupload, this.cloudType)
    },

    // 上传的回调函数
    afterupload (file, data) {
      const params = {
        url: data.Key,
        name: file.name,
        size: file.size,
        ext: file.name.substring(file.name.lastIndexOf(".") + 1),
      }
      this.fileArr.push(params)
      //  //console.log(this.fileArr, ' this.fileArr')
      // this.newFiles = JSON.parse(this.oldFiles)
      //  //console.log(this.newFiles, ' this.newFiles')
      //编辑时上次上传得文件
      const existFiles = this.newFiles.map((val) => {
        return {
          url: val.url,
          name: val.name,
          size: val.size,
          ext: val.ext,
        }
      })

      let key = { qid: this.qid, aid: this.aid, num: null } // 富文本对应的ID，方便对应回复的问题
      if (this.loops) {
        const params = {
          src: [...this.fileArr, ...existFiles],
          loop: this.loops,
        }
        this.$emit("setUrl", params, key)
      } else {
        this.$emit("setUrl", JSON.stringify([...this.fileArr, ...existFiles]), key)
      }
      // //console.log([...this.fileArr, ...existFiles], '88888')
    },
    /**************************** 处理已上传文件 ****************************/
    // 删除图片拦截
    beforeRemove (file, fileList) {
      // console.log(file,fileList);
      // //这里确定之前已经将文件列表删除
      // return this.$confirm(`确定移除【${file.name}】，是否继续?`,'提示',{
      //   confirmButtonText: "确定",
      //   cancelButtonText:"取消",
      //   type: 'warning',
      // })
    },
    // 删除
    handleRemove (file, fileList) {
      // 判断是否是云上传
      if (this.whether) {
        if (file.id) {
          this.newFiles = this.newFiles.filter((item) => item.id != file.id)
        }
        this.cloudLoop(fileList, 2)
      } else {
        this.loop(fileList, 2)
      }
    },
    // 云文件处理
    cloudLoop (fileList, num) {
      let arr = []
      fileList.forEach((val) => {
        if (val.uid) {
          const params = {
            url: val.url,
            name: val.name,
            size: val.size,
            ext: val.ext,
          }
          arr.push(params)
        } else {
          const params = {
            url: this.cloudType + "/" + md5(val.uid.toString()) + getExt(val.raw.name),
            name: val.raw.name,
            size: val.raw.size,
            ext: val.raw.name.substring(val.raw.name.lastIndexOf(".") + 1),
          }
          arr.push(params)
        }
      })
      let key = { qid: this.qid, aid: this.aid, num: num } // 富文本对应的ID，方便对应回复的问题
      if (this.loops) {
        const params = {
          src: arr,
          loop: this.loops,
        }
        this.$emit("setUrl", params, key)
      } else {
        this.$emit("setUrl", JSON.stringify(arr), key)
      }
    },
    // 自定义大图展示
    handleShow (file) {
      // debugger
      // if (!this.resFileList.length) return
      // this.resFileList.forEach((item) => {
      //   if (item.name === file.name && item.size === file.size) {
      //     this.url = item.url
      //     this.file_type = item.ext
      //   }
      // })
      // 打开弹窗
      this.dialogVisible = true
      this.flag = false
      this.$nextTick(() => {
        this.flag = true
        this.url = file.url
      })
    },
    // 自定义下载
    handleDown (file) {
      // let url = ""
      // let name = ""
      // if (!this.resFileList.length) return
      // this.resFileList.forEach(item => {
      //   if (item.name === file.name && item.size === file.size) {
      //     url = item.url
      //     name = item.name
      //   }
      // })
      this.downloadFile(file.url, file.name)
    },
    // 自定义删除
    handleDel (file) {
      this.$refs.upload.uploadFiles = this.$refs.upload.uploadFiles.filter(item => !(item.name === file.name && item.size === file.size))
      let key = { qid: this.qid, aid: this.aid } // 富文本对应的ID，方便对应回复的问题
      this.$emit("setUrl", JSON.stringify(this.$refs.upload.uploadFiles), key)
    },
    // 文件下载
    downloadFile (url, filename) {
      // 创建一个 XMLHttpRequest 对象
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (xhr.status === 200) {
          // 创建一个 Blob 对象
          var blob = new Blob([xhr.response], { type: 'application/octet-stream' })
          // 获取 Blob 对象的 URL
          var url = URL.createObjectURL(blob)
          // 创建一个 a 标签
          var a = document.createElement('a')
          // 设置 a 标签的 download 属性，指定文件名
          a.download = filename
          // 将 a 标签的 href 属性设置为 Blob 对象的 URL
          a.href = url
          // 将 a 标签添加到文档中
          document.body.appendChild(a)
          // 模拟点击 a 标签，触发下载
          a.click()
          // 将 a 标签从文档中移除
          document.body.removeChild(a)
          // 释放 Blob 对象的 URL
          URL.revokeObjectURL(url)
        }
      }
      xhr.send()
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-demo {
  width: 100%;
}

.file-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .file-info,
  .tool-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      font-size: 18px;
      margin-left: 14px;
      color: #909399;
    }
    .file-name {
      color: #606266;
      margin-left: 8px;
    }
    .file-name:hover {
      color: #409eff;
      cursor: pointer;
    }
  }
  .file-info {
    .icon {
      margin-left: 0px;
      font-size: 16px;
    }
  }
}

.file-list {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    .file-info,
    .tool-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        font-size: 18px;
        margin-left: 14px;
        color: #909399;
      }
      .file-name {
        color: #606266;
        margin-left: 8px;
      }
      .file-name:hover {
        color: #409eff;
        cursor: pointer;
      }
    }
    .file-info {
      .icon {
        margin-left: 0px;
        font-size: 16px;
      }
    }

    .tool-icon {
      .icon:hover {
        color: #409eff;
        cursor: pointer;
      }
      .icon:first-child {
        margin-left: 24px;
      }
    }
  }
}
.show_dialog {
  ::v-deep .el-dialog__body {
    max-height: 80vh;
    padding: 10px 20px 30px;
  }
}
</style>
